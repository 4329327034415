// ChatArea.js
import React, { useEffect, useRef } from 'react';
import { LdsLoadingSpinner } from '@elilillyco/ux-lds-react';
import './ChatArea.css';
import DOMPurify from 'dompurify';

const ChatArea = ({ conversationLog, loading }) => {
  const chatEndRef = useRef(null);

  // Scroll to the latest message when conversationLog changes
  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [conversationLog]);

  // Function to process content for newlines and links while preserving HTML formatting
  const processContent = (text) => {
    try {
      const urlRegex = /(https?:\/\/[^\s]+)/g;

      // Replace URLs with anchor tags
      let formattedText = text.replace(
        urlRegex,
        (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer" class="chat-link">${url}</a>`
      );

      // Replace newlines with <br> tags
      formattedText = formattedText.replace(/\n/g, '<br>');

      return formattedText;
    } catch (error) {
      console.error("Error processing content:", error);
      return text;
    }
  };

  return (
    <div className="chat-area">
      {conversationLog.map((log, index) => (
        <div key={index} className={`chat-message ${log.type}`}>
          <div className="chat-sender">{log.type === 'user' ? 'User' : 'Bot'}:</div>
          <div
            className="chat-content"
            dangerouslySetInnerHTML={{ __html: processContent(DOMPurify.sanitize(log.content)) }}
          ></div>
        </div>
      ))}
      {loading && <LdsLoadingSpinner />}
      {/* Scroll anchor to ensure auto-scroll */}
      <div ref={chatEndRef}></div>
    </div>
  );
};

export default ChatArea;







// // ChatArea.js
// import React from 'react';
// import { Box, Typography, CircularProgress } from '@mui/material';

// const ChatArea = ({ conversationLog, loading }) => {
//   return (
//     <Box sx={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
//       {conversationLog.map((log, index) => (
//         <Box key={index} sx={{ marginBottom: '10px' }}>
//           <Typography 
//             variant="body1" 
//             color={log.type === 'user' ? 'primary' : 'secondary'}
//             sx={{ fontWeight: log.type === 'user' ? 'bold' : 'normal' }}
//           >
//             {log.type === 'user' ? 'User' : 'Bot'}:
//           </Typography>
//           <Typography variant="body2" sx={{ marginLeft: '10px' }}>{log.content}</Typography>
//         </Box>
//       ))}
//       {loading && <CircularProgress />}
//     </Box>
//   );
// };

// export default ChatArea;
