import Utils from "../Common/Utils";
import AuthProviderService from "./AuthProviderService";

class ApiService {
    async fetchPersona(onComplete, onError) {
        const env = Utils.getEnvVars();
        let endpoint = env.API_URL;
        let url = endpoint + "";        
        // console.log(url);  // Verify the URL being called

        await this.fetchAuthData(url, onComplete, onError);
    }  

    async fetchAuthData(url, onComplete, onError) {
        try {
            // Retrieve claims (including the token) from the AuthProviderService
            const authClaim = await AuthProviderService.getClaims();

            // Prepare the request headers and body
            // const headersList = {
            //     "Content-Type": "application/json",  // Specify content type
            // };

            // const bodyContent = {
            //     claimtoken: authClaim.idToken  // Include the token in the request body
            // };

            const options = {
                method: "POST",
               
                body: JSON.stringify({'claimtoken':authClaim.idToken}),  // Convert body to JSON string
            };

            // Send the POST request
            const response = await fetch(new Request(url, options))
            // console.log(response);
            
            // Check for network response status
            if (!response.ok) {
                if (onError) onError(new Error('Network response was not ok'));
            } else {
                const data = await response.json();
                console.log('Data received:', data);
                if (onComplete) onComplete(data);
            }
        } catch (error) {
            console.log(error)
            if (error.name === "TimeoutError") {
                console.log("Timeout: It took more than 5 seconds to get the result!");
                // this.fetchGetEDBDataMonitor(url, onComplete, onError);
            } else if (error.name === "AbortError") {
                console.log("Fetch aborted by user action (browser stop button, closing tab, etc.");
                // this.fetchGetEDBDataMonitor(url, onComplete, onError);
            } else if (error.name === "TypeError") {
                console.log("AbortSignal.timeout() method is not supported");
                if (onError) onError(error);
            } else {
                // A network error, or some other problem.
                console.log(`Error: type: ${error.name}, message: ${error.message}`);
                if (onError) onError(error);
            }
        }
    }
}

const apiService = new ApiService();
export default apiService;
