// MessageInput.js
import React, { useState } from 'react';
import { LdsTextField, LdsButton } from '@elilillyco/ux-lds-react';
import './MessageInput.css';

const MessageInput = ({ onSend, isSendDisabled }) => {
  const [message, setMessage] = useState('');

  const handleSend = () => {
    try {
      if (message.trim()) {
        onSend(message);
        setMessage('');
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <div className="message-input-container" style={{ display: 'grid', gridTemplateColumns: '1fr auto', gap: '10px' }}>
      <LdsTextField
        id="message-input"
        name="message"
        placeholder="Select a medicine and start typing your query..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={(e) => e.key === 'Enter' && handleSend()}
        className="message-input-field"
        style={{ width: '100%' }}
      />
      <LdsButton onClick={handleSend} className="send-button" disabled={isSendDisabled || !message.trim()}>
        Send
      </LdsButton>
    </div>
  );
};

export default MessageInput;





// // MessageInput.js
// // MUI
// import React, { useState } from 'react';
// import { Box, TextField, Button } from '@mui/material';

// const MessageInput = ({ onSend }) => {
//   const [message, setMessage] = useState('');

//   const handleSend = () => {
//     if (message.trim()) {
//       onSend(message);
//       setMessage('');
//     }
//   };

//   return (
//     <Box sx={{ display: 'flex', padding: '20px', borderTop: '1px solid #ccc' }}>
//       <TextField
//         fullWidth
//         placeholder="Type a message..."
//         value={message}
//         onChange={(e) => setMessage(e.target.value)}
//         onKeyDown={(e) => e.key === 'Enter' && handleSend()}
//       />
//       <Button variant="contained" onClick={handleSend} sx={{ marginLeft: '10px' }}>Send</Button>
//     </Box>
//   );
// };

// export default MessageInput;
