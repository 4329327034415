// Sidebar.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import { LdsSelect, LdsButton, LdsLillyLogo } from '@elilillyco/ux-lds-react';
import { saveAs } from 'file-saver';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import './Sidebar.css';

const Sidebar = ({ selectedOption, setSelectedOption,onNewChat, conversationLog }) => {
  const options = [
    { value: '', label: 'Select Medicine' },
    { value: 'ABASAGLAR', label: 'ABASAGLAR' },
    { value: 'ALIMTA', label: 'ALIMTA' },
    { value: 'AMYVID', label: 'AMYVID' },
    { value: 'CIALIS', label: 'CIALIS' },
    { value: 'CYMBALTA', label: 'CYMBALTA' },
    { value: 'CYRAMZA', label: 'CYRAMZA' },
    { value: 'DULOXETINE LILLY', label: 'DULOXETINE LILLY' },
    { value: 'EMGALITY', label: 'EMGALITY' },
    { value: 'FORSTEO', label: 'FORSTEO' },
    { value: 'HUMALOG', label: 'HUMALOG' },
    { value: 'JAYPIRCA', label: 'JAYPIRCA' },
    { value: 'LIPROLOG', label: 'LIPROLOG' },
    { value: 'LYUMJEV', label: 'LYUMJEV' },
    { value: 'MOUNJARO', label: 'MOUNJARO' },
    { value: 'OLUMIANT', label: 'OLUMIANT' },
    { value: 'OMVOH', label: 'OMVOH' },
    { value: 'RAYVOW', label: 'RAYVOW' },
    { value: 'RETSEVMO', label: 'RETSEVMO' },
    { value: 'TADALAFIL LILLY', label: 'TADALAFIL LILLY' },
    { value: 'TALTZ', label: 'TALTZ' },
    { value: 'TAUVID', label: 'TAUVID' }, 
    { value: 'TRULICITY', label: 'TRULICITY' },
    { value: 'VERZENIOS', label: 'VERZENIOS' }
];
  
  // Helper function to parse HTML content with structured formatting
  const parseHtmlToDocx = (text) => {
    const parser = new DOMParser();
    const lines = text.split('\n');

    return lines.flatMap((line, lineIndex) => {
      const doc = parser.parseFromString(line, 'text/html');
      const elements = Array.from(doc.body.childNodes);

      const parsedElements = elements.flatMap((el) => {
        switch (el.nodeName) {
          case '#text':
            return new TextRun(el.textContent);
          case 'STRONG':
            return new TextRun({ text: el.textContent, bold: true });
          case 'EM':
            return new TextRun({ text: el.textContent, italics: true });
          case 'A':
            return new TextRun({
              text: el.textContent,
              color: '0000FF',
              underline: true,
            });
          default:
            return new TextRun(el.textContent);
        }
      });

      return [
        ...parsedElements,
        new TextRun({ text: '', break: lineIndex < lines.length - 1 ? 1 : 0 }),
      ];
    });
  };

  // Updated function to download conversation as a Word document
  const downloadConversationAsWord = () => {
    try {
      const docSections = [];
      let lastSelectedOption = null; // To track the last context
    
      conversationLog.forEach((log) => {
        // Only add the context heading when it changes
        if (log.selectedOption && log.selectedOption !== lastSelectedOption) {
          lastSelectedOption = log.selectedOption;
    
          docSections.push(
            new Paragraph({
              children: [
                new TextRun({
                  text: `Conversation Context: ${lastSelectedOption}`,
                  bold: true,
                  size: 28,
                  underline: { type: "single" },
                }),
              ],
              spacing: { after: 400 },
            })
          );
        }
    
        // Sender label with conversational colors for User and Bot
        docSections.push(
          new Paragraph({
            children: [
              new TextRun({
                text: `${log.type === "user" ? "User" : "Bot"}:`,
                bold: true,
                color: log.type === "user" ? "d35400" : "7f8c8d",
              }),
            ],
            spacing: { after: 200 },
          })
        );
    
        // Add message content, formatted to match the web UI’s inline HTML structure
        docSections.push(
          new Paragraph({
            children: parseHtmlToDocx(log.content),
            border: {
              top: log.type === "user" ? { color: "ff6347", size: 6 } : { color: "d3d3d3", size: 6 },
            },
            shading: {
              type: "solid",
              color: log.type === "user" ? "ffe3d3" : "f1f1f1",
            },
            spacing: { after: 300 },
            alignment: log.type === "user" ? "left" : "left",
          })
        );
      });
    
      const doc = new Document({
        sections: [
          {
            children: docSections,
          },
        ],
      });
    
      Packer.toBlob(doc).then((blob) => {
        saveAs(blob, "conversation_log.docx");
      }).catch((error) => {
        console.error("Error creating Word document:", error);
      });
    } catch (error) {
      console.error("Error processing conversation log:", error);
    }
  };
  

  return (
    <Box className="sidebar-container">
      {/* <LdsLillyLogo className="pb-0" removeLink={true} /> */}
      <img src={require('../img/mainlogo.png')} alt="Main Logo" className="main-logo" />
      <Typography variant="h5" className="sidebar-title" align="center" sx={{ marginBottom: '0px' }}>MedAffairs</Typography>
      <Typography variant="h5" className="sidebar-title" align="center" sx={{ marginTop: '-10px' }}>Chatbot</Typography>
      
      <LdsButton onClick={onNewChat} className="new-chat-button">New Chat</LdsButton>
      <LdsButton onClick={downloadConversationAsWord} className="download-button">
        Download Chat
      </LdsButton>
      <LdsSelect
        id="medicine-select"
        label="Select Medicine"
        name="medicineType"
        value={selectedOption}
        onChange={(option) => setSelectedOption(option.value)}
        options={options}
        className="medicine-select"
      />
    </Box>
  );
};

export default Sidebar;



// // Sidebar.js MUI
// import React from 'react';
// import { Box, Button, MenuItem, Select, Typography } from '@mui/material';
// import { saveAs } from 'file-saver';
// import { Document, Packer, Paragraph, TextRun } from 'docx';

// const Sidebar = ({ selectedOption, setSelectedOption, conversationLog }) => {
//   const options = ['Select', 'OLUMIANT', 'VERZENIOS', "MOUNJARO", "CIALIS", "FORSTEO", "CYRAMZA", "OMVOH", "TAUVID", "AMYVID"];

//   const downloadConversationAsWord = () => {
//     const docSections = [];
//     let lastSelectedOption = null;

//     conversationLog.forEach((log) => {
//       if (log.selectedOption !== lastSelectedOption) {
//         lastSelectedOption = log.selectedOption;
//         docSections.push(new Paragraph({
//           children: [new TextRun({ text: `Conversation Context: ${log.selectedOption || 'None'}`, bold: true, size: 28, underline: { type: "single" }})],
//           spacing: { after: 400 },
//         }));
//       }
//       docSections.push(new Paragraph({
//         children: [new TextRun({ text: `${log.type === "user" ? "User" : "Bot"}:`, bold: true, color: log.type === "user" ? "d35400" : "7f8c8d" })],
//         spacing: { after: 200 },
//       }));
//       docSections.push(new Paragraph({ children: [new TextRun(log.content)], spacing: { after: 300 } }));
//     });

//     const doc = new Document({ sections: [{ children: docSections }] });
//     Packer.toBlob(doc).then((blob) => saveAs(blob, 'conversation_log.docx'));
//   };

//   return (
//     <Box sx={{ width: '250px', padding: '20px', bgcolor: 'grey.200' }}>
//       <Typography variant="h6">Options</Typography>
//       <Select
//         value={selectedOption}
//         onChange={(e) => setSelectedOption(e.target.value)}
//         fullWidth
//         variant="outlined"
//         sx={{ marginBottom: '20px' }}
//       >
//         {options.map((option) => (
//           <MenuItem key={option} value={option}>{option}</MenuItem>
//         ))}
//       </Select>
//       <Button variant="contained" fullWidth onClick={() => setSelectedOption('')}>New Chat</Button>
//       <Button variant="contained" fullWidth onClick={downloadConversationAsWord} sx={{ marginTop: '10px' }}>Download Chat</Button>
//     </Box>
//   );
// };

// export default Sidebar;



// import React from 'react';
// import './Sidebar.css';
// import logo from '../img/Lilly_logo.png';

// const Sidebar = ({ setActivePage }) => {
//   return (
//     <div className="sidebar">
//       <img src={logo} alt="Sidebar Top" className="sidebar-image" />
//       <b className="logo">Med Affairs</b>
//       <div className="sidebar-line"></div>
//       <div>
//         <ul>
//           <li>
//             <button onClick={() => setActivePage('Chatbot')} className="sidebar-link">+ New Event</button>
//           </li>
//           <li>
//             <button onClick={() => setActivePage('Upload')} className="sidebar-link">Upload Files</button>
//           </li>
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default Sidebar;
