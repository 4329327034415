// src/App.js
import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
import Chatbot from '../components/Chatbot';
import Header from '../components/Header';
import Upload from '../components/uploadfile';  
import './Home.css';


const App = () => {
  const [activePage, setActivePage] = useState('Chatbot');

  const renderPage = () => {
    switch (activePage) {
      case 'Chatbot':
        return <Chatbot />;
      case 'Upload':
        return <Upload />;
      default:
        return <Chatbot />;
    }
  };
  return (
    <div className="App">
      {/* <Sidebar setActivePage={setActivePage}/> */}
      {/* <Chatbot /> */}
      {/* <Header /> */}
      {renderPage()}
      
      {/* <Upload/> */}
      {/* <Header /> */}
    </div>
  );
}

export default App;
